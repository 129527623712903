import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { SetConfirmacionModalState, SetSpinnerModal } from 'reducers/actions/facturaActions';
import ConfirmacionModal from 'components/modals/confirmacionModal';
import FormularioConsulta from './FormularioConsulta';
import { consultarAdquirentesPorEmisorYNombre as getAdquirentes } from 'network/Api/Adquirente';
import TablaAdquirentes from './TablaAdquirentes';
import ModalAddAdquirente from './ModalAddAdquirente';
import useModal from 'hooks/useModal';
import ModalUpdateAdquirente from './ModalUpdateAdquirente';
import ButtonQR from 'components/organisms/DescargarClienteQRButton';
import ButtonAdquirentes from 'components/organisms/ButtonUploadAdquirentes';

const styles = () => ({
  root: { flexGrow: 2, maxWidth: '100%' },
  fontFamily: { fontFamily: 'Quicksand' },
});

const Adquirentes = ({ classes, userAttributes, setConfirmacionModalState, setSpinnerModal }) => {
  const [listAdquirentes, setListAdquirentes] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [modalNuevo, openNuevo, closeModalNuevo] = useModal();
  const [modalAcualizar, openAcualizar, closeModalAcualizar] = useModal();
  const [adq, setAdq] = React.useState({});

  React.useEffect(() => {
    consultar('');
  }, [userAttributes]);

  const setModal = ({ open = true, text, onClick = () => setModal({ open: false }) }) =>
    setConfirmacionModalState({ open, text, onClick });

  const consultar = async (filtro = '') => {
    const { codigoEmisor: idEmisor } = userAttributes;
    if (idEmisor) {
      setSpinnerModal(true);
      const { data, status } = await getAdquirentes({ idEmisor, filtro });
      setSpinnerModal(false);
      if (status === 200) {
        setListAdquirentes(data);
        setModal({ open: false });
      } else
        setModal({
          text: 'Ocurrió un error consultando los adquirentes, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte.',
        });
    }
  };

  const openEditarAdquirente = (adquirente) => {
    setAdq(adquirente);
    openAcualizar({});
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={8} className={classes.fontFamily}>
        <Grid item>
          <ButtonQR emisor={userAttributes.codigoEmisor} />
        </Grid>
        <Grid item>
          <ButtonAdquirentes emisor={userAttributes.codigoEmisor} />
        </Grid>
        <FormularioConsulta
          onConsulta={consultar}
          onOpenAgregar={() => openNuevo({})}
          filter={filter}
          setFilter={setFilter}
        />
        <Grid item xs={12}>
          <TablaAdquirentes
            adquirentes={listAdquirentes}
            filter={filter}
            onConsulta={consultar}
            onOpenUpdate={openEditarAdquirente}
          />
        </Grid>
      </Grid>
      <ModalAddAdquirente onConsulta={consultar} onClose={closeModalNuevo} {...modalNuevo} />
      <ModalUpdateAdquirente
        onConsulta={consultar}
        onClose={closeModalAcualizar}
        adquirenteUpdate={adq}
        {...modalAcualizar}
      />
      <ConfirmacionModal />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  loading: state.factura.loading,
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Adquirentes));
