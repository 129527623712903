import React, { useState, useEffect } from 'react';
import File from 'components/Inputs/File';
import { Grid } from '@material-ui/core';
import { registrarGasto } from 'network/Api/Gasto';
import { consultarSaldoEmisor } from 'network/Api/Saldo';
import { registrarRecarga } from 'network/Api/Recarga';
import { consultarParametroPorNombre } from 'network/Api/Parametro';
import { consultarHistoricoRegistroGasto } from 'network/Api/HistoricoRegistroGasto';
import EstadosEventos from './ActualizarEstadosEventos.jsx';
import EstadoProceso from './EstadoProceso.js';
import ButtonUploadExcel from 'components/organisms/ButtonUploadExcelCufe/index.jsx';
import Typography from 'components/atoms/Typography';
import ExportExcelIco from 'static/images/excel.png';
import ButtonAtoms from 'components/atoms/Button';
import { Button } from '@material-ui/core';

function RegistrarGasto({ emisor, consultarGastos, loading, openModal, openModalOptions }) {
  const [state, setData] = useState({});
  const [datosHistoricosEventos, setDatosHistoricosEventos] = useState({});
  const [datosHistoricosCufe, setDatosHistoricosCufe] = useState({});

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  const handleChangeFile = (event) => {
    const file = event.target.files[0];
    const { type } = file;
    const disableButton =
      type !== 'text/xml' && type !== 'application/x-zip-compressed' && type !== 'application/zip';
    setState({ file, fileName: file.name, disableButton });
  };

  const guardarGasto = () => {
    validarSaldoEmisor().then((isEnvioInvalido) => {
      if (isEnvioInvalido)
        openModalOptions({
          mensaje: '¿Comprar 1 documento?',
          onAccept: comprarDocumento,
        });
      else enviarGasto();
    });
  };

  const enviarGasto = () => {
    const { file, cufe = '' } = state;
    if (file || cufe) {
      if (cufe.length > 90 || cufe.length === 0) {
        setState({ disableButton: true });
        registrarGasto({ file, emisor, cufe }).then(({ data }) => {
          setState({ disableButton: false });
          openModal(getMessage(data));
          consultarGastos();
          loading(false);
          setState({ file: undefined, fileName: '', cufe: '' });
        });
      } else openModal('CUFE invalido');
    } else openModal('Agrega un CUFE o sube un XML por favor');
  };

  const getMessage = (response) => {
    if (response === 'EXITOSO') return 'XML procesado correctamente';
    return response.startsWith('ERROR') ? 'Error procesando XML' : response;
  };

  const comprarDocumento = () => {
    loading(true);
    consultarParametroPorNombre({ parametro: 'PLAN_RECARGA_UNO' }).then(({ data }) => {
      registrarRecarga({
        emisor: { nmId: emisor },
        plan: { nmId: data },
        cdCanal: 'PARALELA',
      }).then(({ data, status }) => {
        loading(false);
        if (status === 200)
          openModalOptions(
            data
              ? {
                  mensaje: 'Compra realizada correctamente',
                  onAccept: enviarGasto,
                  onCancel: enviarGasto,
                }
              : { mensaje: 'Saldo insuficiente' },
          );
      });
    });
  };

  const validarSaldoEmisor = async () => {
    const { data } = await consultarSaldoEmisor({ emisor });
    const documentosFaltantes = await consultarParametroPorNombre({
      parametro: 'DOCUMENTOS_FALTANTES_RECARGA',
    });
    return data <= documentosFaltantes.data;
  };

  const alertaInformativaEstadoProceso = async () => {
    const { data } = await consultarHistoricoRegistroGasto({ emisor });
    if (data) {
      const dataHistoricosEventos = data.find(
        (item) => item.dsNombreArchivo === 'Eventos Realizados',
      );
      setDatosHistoricosEventos(dataHistoricosEventos);
      const dataHistoricosCufe = data.find((item) => item.dsNombreArchivo !== 'Eventos Realizados');
      setDatosHistoricosCufe(dataHistoricosCufe);
    }
  };

  useEffect(() => {
    alertaInformativaEstadoProceso();
  }, [emisor]);

  return (
    <>
      <Grid
        container
        spacing={8}
        style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-end' }}
      >
        <Grid container style={{ marginBottom: '10px', alignItems: 'flex-center' }}>
          <Grid item xs={3}>
            <Typography
              variant='labelInput'
              style={{ textAlign: 'start', display: 'block', margin: '0 0 0 10px' }}
            >
              Archivo masivo de CUFES
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <ButtonUploadExcel emisor={emisor} datosHistoricosCufe={datosHistoricosCufe} />
          </Grid>
          <Grid item xs={3}>
            <div>
              <Button aria-label='Descargar Excel'>
                <a href='https://s3.us-east-2.amazonaws.com/portalfactura.com/public/Documentos/EjemploArchivoMasivoCUFES.xlsx'>
                  <img alt='exportarExcel' src={ExportExcelIco} />
                </a>
                <span style={{ margin: '0px 10px' }}>Plantilla ejemplo</span>
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: '10px', alignItems: 'flex-center' }}>
          <Grid item xs={3}>
            <Typography
              variant='labelInput'
              style={{ textAlign: 'start', display: 'block', margin: '0 0 0 10px' }}
            >
              Archivo XML o .ZIP
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <File inputText={state.fileName} onChange={handleChangeFile} />
          </Grid>
          <Grid item xs={3}>
            <ButtonAtoms disabled={state.disableButton} onClick={guardarGasto}>
              Subir y procesar
            </ButtonAtoms>
          </Grid>
        </Grid>
        <Typography variant='labelInput' style={{ textAlign: 'center' }}>
          Realizar eventos
        </Typography>
        <Grid item xs={12}>
          <EstadosEventos emisor={emisor} datosHistoricosEventos={datosHistoricosEventos} />
        </Grid>
        <Grid item md={9}>
          <EstadoProceso
            datosHistoricosEventos={datosHistoricosEventos}
            datosHistoricosCufe={datosHistoricosCufe}
          />
        </Grid>
        <Grid item md={3}>
          <ButtonAtoms onClick={alertaInformativaEstadoProceso}>Actualizar Estado</ButtonAtoms>
        </Grid>
      </Grid>
    </>
  );
}

export default RegistrarGasto;
