/* eslint-disable */
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SalirIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import SaldoIcon from '@material-ui/icons/MonetizationOn';
import AddAlert from '@material-ui/icons/AddAlert';
import MoreIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { consultarMensajesPorEmisor } from 'network/Api/Mensaje';
import { consultarConfiguracion } from 'network/Api/Emisor';
import ConfirmacionModal from 'components/modals/confirmacionModal';
import {
  Logout,
  SetAttributesSuperEmisor,
  SetConfirmacionModalState,
  SetSpinnerModal,
  SetUserAttributes,
  SetUserGroup,
} from 'reducers/actions/facturaActions';
import LetraBlanca from 'static/images/Whitelettersnobackground.png';
import LogoPortal from 'static/images/logo_portal_factura.png';
import {
  GetUserData,
  RetrieveCurrentSessionRefreshToken,
  SignOutGlobal,
} from 'screens/login/auth/amplifyAuth';
import 'screens/login/styles.css';
import EmisoresModal from '../modals/EmisoresModal';
import ModalSlider from '../organisms/ModalNoticias/modalSlider';
import SnackBarMensajes from '../modals/snackBarMensajes';
import SpinnerModal from '../modals/spinnerModal';
import MenuUser from './Menus/Menu';

import DrawerMensajes from './DrawerMensajes';
import DrawerMenu from './DrawerMenu';
import DrawerProfile from './DrawerProfile';
import MenuProfile from './MenuProfile';

import * as MenuOptions from './Menus/MenuOptions';
import LabelTitulo from './labelTitulos';

import GrupoUsuarios from 'static/enums/GrupoUsuariosCognito';
import { consultarEstadoTransaccion } from 'network/Api/Emisor';
import { actualizaTransaccionPersona } from 'network/Api/Persona';
import { consultarParametroPorNombre } from 'network/Api/Parametro';
import info from 'static/images/info.jpg';

const drawerWidth = 300;

const styles = (theme) => ({
  root: {},
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: '0px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: '0px',
    marginRight: '10px',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing.unit * 1,
    },
  },
  contentDrawer: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing.unit * 1,
    },
    boxShadow: 'inset 3px 0 3px 0 #128BCC',
    backgroundColor: 'rgb(108,117,125,0.1)',
  },
  containerLogos: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      textAlign: 'center',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    maxHeight: '2em',
  },
  menuLogoLabel: {
    display: 'flex',
    flexDirection: 'row',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      textAlign: 'center',
      flexDirection: 'column',
    },
  },
  menuLabel: {
    width: '70%',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1rem !important',
    },
  },
  rootLogo: {
    display: 'inline',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    maxHeight: 'inherit',
  },
  logo: {
    paddingLeft: '5%',
    maxHeight: 'inherit',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
    },
  },
  logoPortal: {
    maxHeight: 'inherit',
    backgroundColor: '#f2f2f2',
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  saldo: {
    backgroundColor: '#111',
  },
  notificationDot: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    height: '10px',
    width: '10px',
    backgroundColor: 'red',
    borderRadius: '50%',
    border: '1px solid white',
    animation: 'blink 1s infinite',
  },
});

export let gruposUsuarios = {
  Predocumentos: 'Predocumentos',
  Administrador: 'Administrador',
  Emisor: 'Emisor',
  EmisorSinFirma: 'EmisorSinFirma',
  ServicioCliente: 'ServicioCliente',
  Ventas: 'Ventas',
  Parqueadero: 'Parqueadero',
  CargaMasiva: 'CargaMasiva',
  Medico: 'Medico',
  Nomina: 'Nomina',
};

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openNews: false,
      firstClickNews: false,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      right: false,
      redirect: false,
      resultExpiracion: false,
      openEmisoresModal: false,
      listaEmisores: [],
      userAttributesEmisor: {},
      userGroupEmisor: [],
      redirectVersion2: false,
      redirectFacturaAsistida: false,
      redirectPredocumentos: false,
      redirectCompletarDatosEmisor: false,
      redirectVentas: false,
    };
    this.handleMenuCloseLoguot = this.handleMenuCloseLoguot.bind(this);
    this.EmisorSeleccionado = this.EmisorSeleccionado.bind(this);
  }

  async componentDidMount() {
    const { data } = await consultarParametroPorNombre({ parametro: 'BLOQUEO_PORTAL' });
    this.setState({ appOn: data === 'true' });
    let isFirstClickNews = localStorage.getItem('firstClickNews');
    if (isFirstClickNews) {
      this.setState({ firstClickNews: true });
    } else {
      this.setState({ openNews: true });
    }
  }

  componentWillMount() {
    GetUserData().then((result) => {
      if (result === 'not authenticated') return;
      let resGrupo = result.signInUserSession.accessToken.payload['cognito:groups'];
      if (resGrupo === undefined) resGrupo = [''];

      let consultarConfig = false;
      resGrupo.forEach((g) => {
        if (g) {
          const grupoFiltrado = GrupoUsuarios.filter((gr) => gr.grupo === g);
          if (grupoFiltrado[0] && grupoFiltrado[0].config) consultarConfig = true;
        }
      });

      this.setState({
        grupoMenu: resGrupo,
        consultarConfig,
      });
      if (consultarConfig) {
        this.ValidarEstadoTransaccion();
      } else {
        this.ObtenerEmisor();
      }
    });
  }

  ValidarEstadoTransaccion = () => {
    RetrieveCurrentSessionRefreshToken().then(() => {
      GetUserData().then((result) => {
        let identificacionEmisor = result.attributes['custom:custom:userid'];
        this.props.setSpinnerModal(true);
        consultarEstadoTransaccion(identificacionEmisor).then((response) => {
          this.props.setSpinnerModal(false);
          if (response.status === 200) {
            switch (response.data.estado) {
              case 'P':
                this.MostrarModal(
                  'Su transacción de pago está PENDIENTE, por favor contáctese con su asesor de Portal Factura.',
                );
                break;
              case 'A': //Si la transacción es Aprobada
                if (window.location.pathname != '/terminar_registro') {
                  this.setState({ redirectCompletarDatosEmisor: true });
                }
                break;
              case 'F': //Si la transacción es Finalizada
                if (response.data.bloqueado) {
                  this.setState({ grupoMenu: ['EmisorSinFirma'] }, () => this.ObtenerEmisor());
                } else {
                  this.ObtenerEmisor();
                }
                break;
              case 'B':
                this.setState({ grupoMenu: ['EmisorSinFirma'] }, () => this.ObtenerEmisor());
                break;
              case 'R': //Si la transacción es Rechazada
                this.MostrarModal(
                  'Su transacción de pago fue RECHAZADA, por favor realice un nuevo pago o contáctese con su asesor de Portal Factura.',
                );
                break;
            }
          } else {
            this.MostrarModal('No fue posible consultar el estado de la transacción de pago');
          }
        });
      });
    });
  };

  CambiarEstadoTransaccion = (dsIdentificacion, cdEstadoTransaccion) => {
    const { setSpinnerModal } = this.props;
    setSpinnerModal(true);
    actualizaTransaccionPersona({
      dsIdentificacion,
      cdEstadoTransaccion,
    }).then((response) => {
      setSpinnerModal(false);
      if (response.status == 200) {
        if (estado == 'A') {
          this.setState({ redirectCompletarDatosEmisor: true });
        } else {
          this.MostrarModal(
            `El estado de su transacción es RECHAZADA, por favor realice un nuevo pago o contáctese con su asesor de Portal Factura.`,
          );
        }
      } else {
        this.MostrarModal('No se pudo actualizar el estado de la transacción');
        return false;
      }
    });
  };

  MostrarModal = (texto) => {
    let modal = {
      open: true,
      text: texto,
      onClick: () => {
        this.handleMenuCloseLoguot();
      },
      cancelarVisible: true,
    };
    this.props.setSpinnerModal(true);
    this.props.setConfirmacionModalState(modal);
  };

  ObtenerEmisor = () => {
    var fechaActual = new Date().getTime();
    fechaActual = fechaActual.toString().substring(0, 10);
    this.setState({ resultExpiracion: this.validarSesion(fechaActual) }, () => {
      if (this.state.resultExpiracion === undefined) return;
      if (this.state.resultExpiracion == true) {
        RetrieveCurrentSessionRefreshToken().then(() => {
          GetUserData().then((result) => {
            let nuevaHoraExpiracion = parseFloat(fechaActual) + 36000;
            localStorage['fechaActual'] = nuevaHoraExpiracion;
            const dsIdentificacion = result.attributes['custom:custom:userid'];
            const { grupoMenu } = this.state;
            let ruta = MenuOptions.getPaths(grupoMenu).filter(
              (ruta) => ruta === window.location.pathname,
            );
            if (ruta.length < 1) window.location.pathname = MenuOptions.getPrincipalPath();
            if (sessionStorage.getItem('dataEmisor') === null) {
              consultarConfiguracion({ dsIdentificacion }).then((resultConsultaEmisor) => {
                const resultEmisor = {
                  status: resultConsultaEmisor.status,
                  data: {
                    listaEmisores: [resultConsultaEmisor.data],
                  },
                };
                if (resultEmisor.status != 200) {
                  this.MostrarModal(
                    `No fue posible obtener los datos del emisor. Por favor contáctese con soporte de Portal Factura.`,
                  );
                } else {
                  if (this.state.consultarConfig) {
                    this.props.setAttributesSuperEmisor({
                      ...resultEmisor.data,
                    });
                    if (resultEmisor.data.superEmisor) {
                      this.setState({
                        listaEmisores: resultEmisor.data.listaEmisores,
                        userAttributesEmisor: result.attributes,
                        userGroupEmisor: [
                          result.signInUserSession.accessToken.payload['cognito:groups'],
                        ],
                        openEmisoresModal: true,
                      });
                    } else {
                      if (resultEmisor.data.listaEmisores != undefined) {
                        this.props.setUserAttributes({
                          ...result.attributes,
                          ...resultEmisor.data.listaEmisores[0],
                        });
                      } else {
                        this.props.setUserAttributes({
                          ...result.attributes,
                        });
                      }
                      this.props.setUserGroup(
                        result.signInUserSession.accessToken.payload['cognito:groups'],
                      );
                    }
                    sessionStorage.setItem('dataEmisor', JSON.stringify(resultEmisor.data));
                  }
                }
              });
            } else {
              let dataEmisor = JSON.parse(sessionStorage.getItem('dataEmisor'));
              this.props.setAttributesSuperEmisor({ ...dataEmisor });
              if (dataEmisor.superEmisor) {
                this.setState({
                  listaEmisores: dataEmisor.listaEmisores,
                  userAttributesEmisor: result.attributes,
                  userGroupEmisor: [result.signInUserSession.accessToken.payload['cognito:groups']],
                  openEmisoresModal: true,
                });
              } else {
                if (dataEmisor.listaEmisores != undefined) {
                  this.props.setUserAttributes({
                    ...result.attributes,
                    ...dataEmisor.listaEmisores[0],
                  });
                } else {
                  this.props.setUserAttributes({ ...result.attributes });
                }
                this.props.setUserGroup([
                  result.signInUserSession.accessToken.payload['cognito:groups'],
                ]);
              }
            }
          });
        });
      }
    });
  };

  EmisorSeleccionado = (emisor) => {
    this.setState({ openEmisoresModal: false }, () => {
      this.props.setUserGroup(this.state.userGroupEmisor);
      this.props.setUserAttributes({
        ...this.state.userAttributesEmisor,
        ...emisor,
      });
    });
  };

  validarSesion = (fechaActual) => {
    let resultado = '';
    let fechaExpiracionToken = localStorage.getItem('fechaActual');
    if (fechaExpiracionToken < fechaActual) {
      alert('Su sesión ha expirado. Por favor inicie sesión nuevamente');
      resultado = false;
      this.handleMenuCloseLoguot();
    } else {
      resultado = true;
    }
    return resultado;
  };

  toggleDrawer = (side, open) => () => {
    this.setState({ [side]: open });
  };

  handleNewsModalOpen = () => {
    this.setState({ openNews: true });
    this.handleChangeNotification;
  };
  handleChangeNotification = () => {
    this.setState({ firstClickNews: true });
    localStorage['firstClickNews'] = true;
  };

  handleCheckNews = () => {
    this.setState({ firstClickNews: false });
  };

  handleNewsModalClose = () => {
    this.setState({ openNews: false });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleMenuCloseLoguot = () => {
    SignOutGlobal().then(() => {
      localStorage.removeItem('fechaActual');
      sessionStorage.removeItem('dataEmisor');
      this.setState({ redirect: true }, () => {
        this.props.logout();
      });
    });
  };

  handleClickConfirmarLogout = () => {
    let modal = {
      open: true,
      text: '¿Seguro que desea cerrar sesión?',
      onClick: this.handleMenuCloseLoguot,
    };
    this.props.setConfirmacionModalState(modal);
  };

  handleOpenMensajes = () => {
    this.setState({ notifyOpen: true });
  };

  getMensajesSinLeer = (mensajes) =>
    Array.isArray(mensajes) &&
    mensajes
      .filter((mensaje) => mensaje.snLeido === 'N')
      .map((mensaje) => ({ nmId: mensaje.nmId, snLeido: 'S' }));

  getTitle(grupoMenu) {
    if (!grupoMenu) return '';
    const title = MenuOptions.getTitle(
      grupoMenu,
      window.location.pathname + window.location.search,
    );
    return title ? title.charAt(0).toUpperCase() + title.slice(1).toLowerCase() : '';
  }

  render() {
    const { classes, theme, children, userAttributes } = this.props;
    const {
      anchorEl,
      mobileMoreAnchorEl,
      redirect,
      openEmisoresModal,
      listaEmisores,
      notifyOpen,
      mensajes,
      mensajesSinLeer,
      grupoMenu,
      redirectCompletarDatosEmisor,
      appOn,
    } = this.state;
    const title = this.getTitle(grupoMenu);
    const isMenuOpen = Boolean(anchorEl);
    if (redirect) return <Redirect to={{ pathname: '/login' }} />;
    if (redirectCompletarDatosEmisor) return <Redirect to={{ pathname: '/terminar_registro' }} />;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position='fixed'
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Link
              style={{ textDecoration: 'none' }}
              to={{
                pathname: '/Home',
              }}
              className={classNames(classes.menuLogoLabel)}
            >
              <div container='true' className={classes.containerLogos}>
                <div item='true' xs={6} className={classes.rootLogo}>
                  <img className={classes.logoPortal} alt='client' src={LogoPortal} />
                  <img className={classes.logo} alt='client' src={LetraBlanca} />
                </div>
              </div>

              <LabelTitulo
                tipo='enabezadoBlanco'
                className={classNames(classes.menuLabel)}
                texto={grupoMenu && title}
              />
            </Link>
            <div className={classes.grow} />
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <MenuItem
                style={{ color: 'white', fontWeight: 'bold' }}
                onClick={this.handleNewsModalOpen}
              >
                Anuncios
                <IconButton onClick={this.handleNewsModalOpen} color='inherit'>
                  <AddAlert id='alert-menu-icon' />
                  {!this.state.firstClickNews && <span className={classes.notificationDot}></span>}
                </IconButton>
              </MenuItem>
              <MenuItem style={{ color: 'white' }}>{userAttributes.nombresEmisor}</MenuItem>
              <MenuItem
                style={{ color: 'white', fontWeight: 'bold' }}
                onClick={() => (window.location.href = 'solicitudes')}
              >
                PQR
              </MenuItem>
              <MenuItem
                style={{ color: 'white', fontWeight: 'bold' }}
                onClick={() => (window.location.href = 'Home')}
              >
                Inicio
              </MenuItem>
              <IconButton onClick={this.toggleDrawer('right', true)} color='inherit'>
                <SaldoIcon />
              </IconButton>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup='true'
                onClick={this.handleProfileMenuOpen}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup='true' onClick={this.handleMobileMenuOpen} color='inherit'>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          <MenuProfile
            anchorEl={anchorEl}
            userAttributes={userAttributes}
            isMenuOpen={isMenuOpen}
            mobileMoreAnchorEl={Boolean(anchorEl)}
            isMobileMenuOpen={Boolean(mobileMoreAnchorEl)}
            confirmLogOut={this.handleClickConfirmarLogout}
            handleMenuClose={this.handleMenuClose}
            profileMenuOpen={this.handleProfileMenuOpen}
            handleOpenDrawer={this.toggleDrawer('right', true)}
          />
        </AppBar>

        <DrawerMenu
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          userAttributes={userAttributes}
          listaMenu={<MenuUser menuOptions={grupoMenu} />}
          theme={theme}
          drawer={classes.drawer}
          drawerOpen={classes.drawerOpen}
          drawerClose={classes.drawerClose}
          toolbar={classes.toolbar}
          closeSession={
            <List>
              {['Cerrar sesión'].map((text, _) => (
                <ListItem button key={text}>
                  <ListItemIcon>
                    {' '}
                    <SalirIcon onClick={this.handleClickConfirmarLogout} />
                  </ListItemIcon>
                  <ListItemText onClick={this.handleClickConfirmarLogout} primary={text} />
                </ListItem>
              ))}
            </List>
          }
        />

        <DrawerMensajes
          isOpen={notifyOpen}
          handleClose={() => this.setState({ notifyOpen: false })}
          handleConsulta={this.consultarMensajes}
          mensajes={mensajes}
        />

        <DrawerProfile
          userAttributes={userAttributes}
          classes={classes}
          open={this.state.right}
          handleClose={this.toggleDrawer('right', false)}
          classesContentDrawer={classes.contentDrawer}
          classesToolbar={classes.toolbar}
          classesSaldo={classes.saldo}
        />

        <ConfirmacionModal />
        {listaEmisores.length > 0 && (
          <EmisoresModal
            open={openEmisoresModal}
            emisores={listaEmisores}
            onClick={this.EmisorSeleccionado}
          />
        )}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {!appOn ? (
            children
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}>
              <img src={info} alt='' />
            </div>
          )}
        </main>
        <SpinnerModal />
        <SnackBarMensajes />
        <ModalSlider
          openM={this.state.openNews}
          checkNews={this.handleCheckNews}
          onClose={this.handleNewsModalClose}
          onLastSlide={this.handleChangeNotification}
        />
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    userAttributes: state.factura.userAttributes,
    userGrupo: state.factura.userGrupo,
    fechaExpiracionToken: state.factura.fechaExpiracionToken,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  logout: (item) => dispatch(Logout(item)),
  setUserAttributes: (item) => dispatch(SetUserAttributes(item)),
  setUserGroup: (item) => dispatch(SetUserGroup(item)),
  setAttributesSuperEmisor: (item) => dispatch(SetAttributesSuperEmisor(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Layout));
