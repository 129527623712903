import { serviceRoutes, get, post, put, postFormDataa } from './index';

export async function consultarAdquirentesPorEmisorYNombre({ idEmisor, filtro = '', estado }) {
  let url = `?idemisor=${idEmisor}&nombre=${filtro}`;

  if (estado === 'A') {
    url += `&estado=${estado}`;
  }

  return await get(serviceRoutes.consultarAdquirentesPorEmisorYNombre, url);
}

export const registrarAdquirente = async (body) =>
  await post(serviceRoutes.registrarAdquirente, body);

export const actualizarAdquirente = async (body) =>
  await put(serviceRoutes.actualizarAdquirente, body);

export const activarDesactivarAdquirente = async (body) =>
  await put(serviceRoutes.activarDesactivarAdquirente, body);

export const consultarAdquirentePorId = async ({ id }) =>
  await get(serviceRoutes.consultarAdquirentePorId, `?id=${id}`);

export const registrarActualizarAdquirente = async (body) =>
  await post(serviceRoutes.registrarActualizarAdquirente, body);

export const registrarAdquirentePos = async (body, token) =>
  await post(`${serviceRoutes.registrarAdquirentePos}?token=${token}`, body);

export const consultarAdquirentePorIdentificacion = async ({ id, identificacion }) =>
  await get(
    serviceRoutes.consultarAdquirentePorIdentificacion,
    `?identificacion=${identificacion}&id=${id}`,
  );

export const generarTokenAutoRegistro = async ({ id }) =>
  await get(serviceRoutes.generarTokenAutoRegistroAdquirente, `?id=${id}`);

export const cargaMasivaAdquirentes = async (body) =>
  await postFormDataa(serviceRoutes.cargaMasivaAdquirentes, body);
