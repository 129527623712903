import * as React from 'react';
import Button from 'components/atoms/Button';
import useModal from 'hooks/useModal';
import Modal from 'components/molecules/Modal';
import Input from 'components/molecules/Input';
import useForm from 'hooks/useForm';
import { actualizarEstadosGastos, consultarSaldoMasivoEstados } from 'network/Api/Gasto';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

const f = 'YYYY-MM-DD';
const stateD = { feInicio: now().format('YYYY-MM-01'), feFin: now().format(f) };

function ActualizarEstados({ emisor, datosHistoricosEventos }) {
  const [messageProps, openMessage] = useModal();
  const { state, handleChange } = useForm(stateD);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const { feInicio, feFin, dsNit: dsNitEmisor } = state;
      const validarSaldo = await consultarSaldoMasivoEstados({
        emisor,
        feInicio,
        feFin,
        dsNitEmisor,
      });
      if (validarSaldo.data) {
        if (
          datosHistoricosEventos &&
          datosHistoricosEventos.nmRegistrosProcesados ===
            datosHistoricosEventos.nmCantidadRegistros
        ) {
          await actualizarEstadosGastos({ emisor, feInicio, feFin, dsNitEmisor });
          openMessage({ content: 'Actualizando eventos podras revisa los cambios mas tarde.' });
        } else {
          openMessage({
            content:
              'Actualmente hay un proceso en ejecución. Una vez finalice, podrá subir otro documento.',
          });
        }
      } else {
        openMessage({
          content:
            'No cuenta con saldo suficiente para procesar los documentos. por favor recargar antes de continuar con la operación. ',
        });
      }
    },
    [state, emisor, datosHistoricosEventos],
  );

  return (
    <>
      <Modal {...messageProps} />

      <form onSubmit={onSubmit}>
        <div className='form-row align-items-end'>
          <Input
            className='form-group col-lg-3 col-6'
            label='Fecha Inicio'
            type='date'
            name='feInicio'
            onChange={handleChange}
            value={state.feInicio}
            max={state.feFin}
            require
          />
          <Input
            className='form-group col-lg-3 col-6'
            label='Fecha Fin'
            type='date'
            name='feFin'
            onChange={handleChange}
            value={state.feFin}
            min={state.feInicio}
            require
          />
          <Input
            className='form-group col-lg-3 col-6'
            label='NIT'
            name='dsNit'
            onChange={handleChange}
            value={state.dsNit}
          />
          <div className='form-group col-lg-3 col-6'>
            <Button type='submit'>Procesar</Button>
          </div>
        </div>
        <p>
          <b>Nota:</b> tener presente que, si el evento del documento es '<b>notificar</b>' o '
          <b>notificar recibido</b>', este se aceptará automáticamente.
        </p>
      </form>
    </>
  );
}

export default ActualizarEstados;
