import * as React from 'react';
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button';
import Input from 'components/molecules/Input';
const { useMemo } = React;

const FiltroConsultas = ({ handleChange, consultarGastos, state }) => {
  const { feInicio, feFin, prefijo, numero, nombreEmisor, cufe, dsIdEmisor } = useMemo(
    () => state,
    [state],
  );
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <Input
          labelVariant='labelInput'
          label='Fecha inicio'
          type='date'
          name='feInicio'
          value={feInicio}
          onChange={handleChange}
          max={feFin}
        />
      </Grid>
      <Grid item md={3}>
        <Input
          labelVariant='labelInput'
          label='Fecha fin'
          type='date'
          name='feFin'
          value={feFin}
          onChange={handleChange}
          min={feInicio}
        />
      </Grid>
      <Grid item md={3}>
        <Input
          labelVariant='labelInput'
          label='Prefijo'
          name='prefijo'
          value={prefijo}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={3}>
        <Input
          labelVariant='labelInput'
          label='Folio'
          name='numero'
          value={numero}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={12}>
        <Input
          labelVariant='labelInput'
          label='CUFE'
          name='cufe'
          value={cufe}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={6}>
        <Input
          labelVariant='labelInput'
          label='Nombre emisor'
          name='nombreEmisor'
          value={nombreEmisor}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={6}>
        <Input
          labelVariant='labelInput'
          label='NIT del emisor'
          name='dsIdEmisor'
          value={dsIdEmisor}
          onChange={handleChange}
        />
      </Grid>
      <Grid
        style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-around' }}
        xs={12}
        item
      >
        <Button onClick={consultarGastos}>Consultar</Button>
      </Grid>
    </Grid>
  );
};

export default FiltroConsultas;
