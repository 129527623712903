import { serviceRoutes, postFormData, get, post, postFormDataa } from './index';

export const registrarGasto = async (body) =>
  await postFormData(serviceRoutes.registrarGasto, body);

export const consultarGastos = async ({
  emisor,
  feInicio,
  feFin,
  prefijo = '',
  numero = '',
  nombreEmisor = '',
  cufe = '',
  dsNitEmisor = '',
}) =>
  await get(
    serviceRoutes.consultarGastos,
    `?id=${emisor}&inicio=${feInicio}&fin=${feFin}&prefijo=${prefijo}&numero=${numero}&emisor=${nombreEmisor}&cufe=${cufe}&dsVendedor=${dsNitEmisor}`,
  );

export const actualizarEventoGasto = async (body) =>
  await post(serviceRoutes.actualizarEventoGasto, body);

export const actualizarEstadosGastos = async ({ emisor, feInicio, feFin, dsNitEmisor = '' }) =>
  await get(
    serviceRoutes.actualizarEstadosGastos,
    `?id=${emisor}&inicio=${feInicio}&fin=${feFin}&dsVendedor=${dsNitEmisor}`,
  );

export const subirExcelCufesGastos = async (body) =>
  await postFormDataa(serviceRoutes.subirExcelCufesGastos, body);

export const consultarSaldoMasivoEstados = async ({ emisor, feInicio, feFin, dsNitEmisor = '' }) =>
  await get(
    serviceRoutes.consultarSaldoMasivoEstados,
    `?emisor=${emisor}&inicio=${feInicio}&fin=${feFin}&dsVendedor=${dsNitEmisor}`,
  );

export const consultarSaldoMasivoExcel = async (body) =>
  await postFormDataa(serviceRoutes.consultarSaldoMasivoExcel, body);
