import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'screens/login/styles.css';
import {
  SetConfirmacionModalState,
  SetLoadingValue,
  SetPlanes,
  SetSpinnerModal,
} from 'reducers/actions/facturaActions';
import { connect } from 'react-redux';
import { registrarRecarga, guardarRecargaPin } from 'network/Api/Recarga';
import { validarPinTransaccion } from 'network/Api/Transaccion';
import { consultarParametroPorNombre } from 'network/Api/Parametro';
import Input from 'components/Inputs/Input';
import TransferirSaldo from './TransferirSaldo';
import ComprarSaldo from './ComprarSaldo';
import Typography from 'components/atoms/Typography';
// import { diferenciaDias } from 'utils/DateUtils';

const URL_PASARELA = process.env.REACT_APP_PASARELA_PAGO;

const customStyles = {
  input: (styles) => ({
    ...styles,
    height: '2.25rem',
  }),
};

class RecargarSaldo extends Component {
  state = {
    tipoRecarga: true, // Tipo de recarga (false: compra de plan, true: compra con PIN)
    codigoPlan: null,
    valorPlan: null,
    dsPin: '',
    planes: [],
  };

  componentDidMount() {
    consultarParametroPorNombre({
      parametro: 'MINIMA_RECARGA_DINERO',
    }).then((res) => this.setState({ compraMinima: res.data }));
  }

  openModal = (texto, onClick) => {
    const { setConfirmacionModalState } = this.props;
    setConfirmacionModalState({
      open: true,
      text: texto,
      onClick:
        onClick !== null ? () => onClick() : () => setConfirmacionModalState({ open: false }),
    });
  };

  handleChange = (event) => {
    const label = event.target.id;
    const value = event.target.value;
    switch (label) {
      case 'tipoRecarga':
        this.setState({ tipoRecarga: !this.state.tipoRecarga });
        break;
      default:
        this.setState({ [label]: value });
        break;
    }
  };

  comprar = async (event) => {
    event.preventDefault();
    const { tipoRecarga } = this.state;
    let response = tipoRecarga ? await this.comprarPin() : await this.comprarPasarela();
    if (response.status) {
      if (tipoRecarga)
        this.openModal('Compra realizada correctamente', () => {
          window.location.href = response.redirect;
        });
      else window.open(response.redirect);
    } else this.openModal(response.msg ? response.msg : 'Error realizando la recarga', null);
  };

  comprarPasarela = async () => {
    let responseValidation = this.validarCompraPasarela();
    return {
      status: true,
      msg: responseValidation.errors,
      redirect: responseValidation.redirect,
    };
  };

  validarCompraPasarela = () => {
    const { compraMinima, nmValorRecarga } = this.state;
    let errors = [];
    if (parseFloat(nmValorRecarga) < parseFloat(compraMinima))
      errors.push(`El valor de recarga tiene que ser superior a ${compraMinima}`);
    return {
      redirect: URL_PASARELA,
      errors,
    };
  };

  comprarPin = async () => {
    const { setLoadingValue } = this.props;
    setLoadingValue(true);
    let response = await this.validarPin().then((validaciones) => {
      if (validaciones.errors.length > 0) {
        return { msg: validaciones.errors };
      } else {
        return guardarRecargaPin(validaciones.body);
      }
    });
    setLoadingValue(false);
    return {
      ...response,
      redirect: '/Home',
    };
  };

  validarPin = async () => {
    const { dsPin } = this.state;
    const { userAttributes } = this.props;
    let estadoPin = await validarPinTransaccion({ dsPin });
    let errors = [];
    if (dsPin.length < 1) errors.push('El PIN es obligatorio');
    if (estadoPin.data !== 'VIGENTE') errors.push('PIN Invalido o ya no se encuentra vigente');
    return {
      body: {
        dsPin,
        emisorId: userAttributes.codigoEmisor,
      },
      errors,
    };
  };

  openModalForm = ({ titleForm, tipoCompra }) => {
    this.setState({
      openModalForm: true,
      titleForm,
      tipoCompra,
    });
  };

  comprarPaquete = () => {
    const { plan } = this.state;
    const { userAttributes, setSpinnerModal } = this.props;
    if (plan) {
      setSpinnerModal(true);
      registrarRecarga({
        emisor: { nmId: userAttributes.codigoEmisor },
        plan: { nmId: plan },
        cdCanal: 'PARALELA',
      }).then((res) => {
        setSpinnerModal(false);
        if (res.status === 200 && res.data === true)
          this.openModal('Compra realizada correctamente', () =>
            this.props.setConfirmacionModalState({ open: false }),
          );
        else if (res.status === 200 && res.data === false)
          this.openModal('No tienes suficiente saldo para comprar este plan', () =>
            this.props.setConfirmacionModalState({ open: false }),
          );
        else
          this.openModal('Error realizando compra', () =>
            this.props.setConfirmacionModalState({ open: false }),
          );
      });
      this.setState({ openModalForm: false, plan: null });
    } else {
      this.openModal('Por favor selecciona un plan', () =>
        this.props.setConfirmacionModalState({ open: false }),
      );
    }
  };

  render() {
    const { loading, classes, userAttributes } = this.props;
    const { dsPin } = this.state;
    // const { feVencimientoSaldo } = userAttributes;

    return (
      <div className={classes.root}>
        <form className={classes.fontFamily}>
          {/* <Grid item className='justify-content-center align-items-center col-12'>
            <Typography variant='labelEncabezado'>
              Su saldo vence en la fecha {feVencimientoSaldo}
            </Typography>
            <Typography>Le quedan {diferenciaDias(feVencimientoSaldo).toFixed(1)} dias</Typography>
          </Grid> */}
          <Grid
            container
            spacing={9}
            className='justify-content-center align-items-center no-shadow'
          >
            <Grid item className='justify-content-center align-items-center col-sm-12 col-md-6'>
              <ComprarSaldo userAttributes={userAttributes} />
              <div className='col-sm-12 col-md-12 col-lg-12 mx-auto'>
                <div className='card-signin'>
                  <div className='card-body'>
                    <div className='form-row col-md-12 d-flex justify-content-between'>
                      <div className='form-group col-md-12 col-xl-12'>
                        <Typography variant='label'>Si tiene un PIN ingreselo aquí</Typography>
                      </div>
                      <div className='form-group col-md-12 col-xl-5'>
                        <Input
                          placeholder='PIN'
                          style={{ height: '2.9rem' }}
                          value={dsPin}
                          id='dsPin'
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='form-group col-md-12 col-xl-7 mb-0'>
                        <button
                          disabled={loading}
                          className='btn btn-lg btn-primary btn-block text-uppercase'
                          type='submit'
                          onClick={this.comprar}
                        >
                          {loading && (
                            <span
                              className='spinner-border spinner-border-sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}
                          Activar PIN
                        </button>
                      </div>
                      <div className='form-group col-md-12 col-xl-12 mb-0' />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid className='justify-content-center align-items-center col-sm-12 col-md-6'>
              <TransferirSaldo userAttributes={userAttributes} />
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

RecargarSaldo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, _) => {
  return {
    loading: state.factura.loading,
    planesPrepago: state.factura.planes,
    userAttributes: state.factura.userAttributes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  setLoadingValue: (item) => dispatch(SetLoadingValue(item)),
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  setPlanes: (item) => dispatch(SetPlanes(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(customStyles)(RecargarSaldo));
