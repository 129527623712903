import * as React from 'react';
import ButtonFile from 'components/molecules/ButtonFile';
import Modal from 'components/molecules/Modal';
import useModal from 'hooks/useModal';
import { cargaMasivaProductos } from 'network/Api/Producto';
const { useCallback } = React;

export default function ButtonUploadProductos({ emisor }) {
  const [modalProps, openModal] = useModal();

  const onChangeFile = useCallback(
    async function (event) {
      const [file] = event.target.files;
      const errores = validarFile(file);
      if (!errores.length) {
        cargaMasivaProductos({ emisor, file });
        openModal({
          content: 'Archivo en proceso. Puedes cerrar, y el sistema seguirá trabajando.',
        });
      } else {
        openModal({ content: errores.join(', ') });
      }
    },
    [emisor],
  );

  const validarFile = useCallback(function (file) {
    let errores = [];
    const { name } = file;
    if (!(name.endsWith('.xls') || name.endsWith('.xlsx')))
      errores.push('El archivo no es de tipo Excel');
    return errores;
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ButtonFile onChange={onChangeFile}>Cargar productos</ButtonFile>
      <small>
        <a
          href='https://s3.us-east-2.amazonaws.com/portalfactura.com/public/creacion+de+productos+plantilla.xlsx'
          target='_blank'
          rel='noopener noreferrer'
        >
          Descargar
        </a>{' '}
        plantilla para carga de productos
      </small>
      <Modal {...modalProps} />
    </div>
  );
}
