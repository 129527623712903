import React, { useState, useEffect } from 'react';
import Typography from 'components/atoms/Typography';
import { Grid } from '@material-ui/core';

function EstadoProceso({ datosHistoricosEventos, datosHistoricosCufe }) {
  const [historicoGastos, setHistoricoGastos] = useState({
    eventosRealizados: '',
    procesamientoCufes: '',
  });

  const alertaInformativaEstadoProceso = async () => {
    const mensajeEventosRealizados = construirMensaje(datosHistoricosEventos);
    const mensajeProcesamientoCufes = construirMensaje(datosHistoricosCufe);
    setHistoricoGastos({
      eventosRealizados: mensajeEventosRealizados,
      procesamientoCufes: mensajeProcesamientoCufes,
    });
  };

  const construirMensaje = (data) => {
    if (!data) {
      return 'No hay ningún proceso en ejecución actualmente.';
    }
    if (data.nmRegistrosProcesados < data.nmCantidadRegistros) {
      return `El archivo de nombre ${data.dsNombreArchivo} ha procesado ${data.nmRegistrosProcesados} de ${data.nmCantidadRegistros} documentos.`;
    }
    if (data.nmRegistrosProcesados === data.nmCantidadRegistros) {
      return `El archivo de nombre ${data.dsNombreArchivo} ha procesado ${data.nmRegistrosProcesados} de ${data.nmCantidadRegistros} documentos. Ha finalizado.`;
    }
  };

  useEffect(() => {
    alertaInformativaEstadoProceso();
  }, [datosHistoricosEventos, datosHistoricosCufe]);

  return (
    <>
      <Typography variant='labelInput' style={{ textAlign: 'start', margin: '0 0 0 10px' }}>
        Estado de proceso
      </Typography>
      <Grid container style={{ textAlign: 'start', margin: '20px 10px' }}>
        <Grid item md={12}>
          <b>Archivo masivo de CUFES: </b> {historicoGastos.procesamientoCufes} <br />
          <b>Realizar eventos: </b> {historicoGastos.eventosRealizados}
        </Grid>
      </Grid>
    </>
  );
}
export default EstadoProceso;
