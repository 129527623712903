export function getDevengadosList({ Devengados = {} }) {
  let devengadosList = [];
  for (const dsTipo in Devengados) {
    const devengadoJson = Devengados[dsTipo];
    if (dsTipo !== 'Varios') {
      if (!Array.isArray(devengadoJson)) {
        if (dsTipo === 'Vacaciones') {
          if (devengadoJson.VacacionesComunes)
            pushDevengadoArray(devengadosList, devengadoJson.VacacionesComunes[0], dsTipo);
          if (devengadoJson.VacacionesCompensadas)
            pushDevengadoArray(devengadosList, devengadoJson.VacacionesCompensadas[0], dsTipo);
        } else pushDevengadoArray(devengadosList, devengadoJson, dsTipo);
      } else
        devengadoJson.forEach((element) => pushDevengadoArray(devengadosList, element, dsTipo));
    } else {
      for (const variosTipo in devengadoJson) {
        pushDevengadoArray(devengadosList, { Pago: devengadoJson[variosTipo] }, variosTipo);
      }
    }
  }
  return devengadosList;
}

function pushDevengadoArray(devengadosList, devengadoJson, dsTipo) {
  devengadosList.push({
    dsTipo: parseIncapacidad(devengadoJson.Tipo) || dsTipo,
    nmPago:
      devengadoJson.SueldoTrabajado ||
      devengadoJson.Pago ||
      devengadoJson.PagoS ||
      devengadoJson.Comision ||
      devengadoJson.PagosTercero ||
      devengadoJson.Anticipo,
    nmPorcentaje: devengadoJson.Porcentaje,
    nmCantidad: devengadoJson.DiasTrabajados || devengadoJson.Cantidad,
    nmAuxilioNS: devengadoJson.AuxilioNS,
    nmAuxilioS: devengadoJson.AuxilioS,
    nmBonificacionNS: devengadoJson.BonificacionNS,
    nmBonificacionS: devengadoJson.BonificacionS,
    nmPagoNS: devengadoJson.PagoNS,
    nmPagoAlimentacionS: devengadoJson.PagoAlimentacionS,
    nmPagoAlimentacionNS: devengadoJson.PagoAlimentacionNS,
    nmPagoIntereses: devengadoJson.PagoIntereses,
    nmCompensacionO: devengadoJson.CompensacionO,
    nmCompensacionE: devengadoJson.CompensacionE,
    dsDescripcionConcepto: devengadoJson.DescripcionConcepto,
    nmConceptoS: devengadoJson.ConceptoS,
    nmConceptoNS: devengadoJson.ConceptoNS,
    nmAuxilioTransporte: devengadoJson.AuxilioTransporte,
    nmViaticoManuAlojS: devengadoJson.ViaticoManuAlojS,
    nmViaticoManuAlojNS: devengadoJson.ViaticoManuAlojNS,
  });
}

export function getDeduccionesList({ Deducciones = {} }) {
  let deduccionesList = [];
  for (const dsTipo in Deducciones) {
    const deduccionJson = Deducciones[dsTipo];
    if (typeof deduccionJson === 'string')
      pushDeduccionArray(deduccionesList, { Deduccion: deduccionJson }, dsTipo);
    else if (!Array.isArray(deduccionJson))
      pushDeduccionArray(deduccionesList, deduccionJson, dsTipo);
    else deduccionJson.forEach((element) => pushDeduccionArray(deduccionesList, element, dsTipo));
  }
  return deduccionesList;
}

function pushDeduccionArray(deduccionesList, deduccionJson, dsTipo) {
  deduccionesList.push({
    dsTipo,
    nmPorcentaje: deduccionJson.Porcentaje,
    nmDeduccion:
      deduccionJson.Deduccion ||
      deduccionJson.PagosTercero ||
      deduccionJson.OtraDeduccion ||
      deduccionJson.Anticipo,
    nmDeduccionSp: deduccionJson.DeduccionSP,
    nmPorcentajeSub: deduccionJson.PorcentajeSub,
    nmDeduccionSub: deduccionJson.DeduccionSub,
    nmSancionPublic: deduccionJson.SancionPublic,
    nmSancionPriv: deduccionJson.SancionPriv,
    dsDescripcion: deduccionJson.Descripcion,
  });
}

const parseIncapacidad = (code) => {
  switch (code) {
    case '1':
      return 'Incapacidad Comun';
    case '2':
      return 'Incapacidad Profesional';
    case '3':
      return 'Incapacidad Laboral';
    default:
      return code;
  }
};
