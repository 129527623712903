import * as React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Table from 'components/Tablas/GenericaOrdenadaColumna';
import RegistrarGasto from './RegistrarGasto';
import { consultarGastos } from 'network/Api/Gasto';
import FiltroConsultas from './FiltroConsultas';
import { now, dateString } from 'utils/DateUtils';
import ActualizarEstado from './ActualizarEstadoEvento';
import { SetSpinnerModal } from 'reducers/actions/facturaActions';
import Modal from 'components/modal/ModalConfirmacion';
import ButtonDescargaArchivos from 'components/organisms/ButtonDescargaArchivos';
import Icon from 'components/molecules/Icon';
import { Paper } from '@material-ui/core';
import Typography from 'components/atoms/Typography';
const { useCallback, useMemo } = React;

const Gastos = ({ userAttributes, setSpinnerModal }) => {
  const [state, setData] = React.useState({
    feInicio: now().format('YYYY-MM-01'),
    feFin: now().format('YYYY-MM-DD'),
    openModal: false,
  });

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  React.useEffect(() => {
    if (userAttributes.codigoEmisor) consultar();
  }, [userAttributes]);

  const consultar = useCallback(async () => {
    const { feInicio, feFin, prefijo, numero, nombreEmisor, cufe } = state;
    const { codigoEmisor: emisor } = userAttributes;
    setState({ loading: true });
    const body = { emisor, feInicio, feFin, prefijo, numero, nombreEmisor, cufe };
    const { data } = await consultarGastos(body);
    setState({ gastos: data, loading: false });
  }, [state, userAttributes]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  const openModal = (mensaje) =>
    setState({
      openModal: true,
      mensaje,
      onModalAccept: () => setState({ openModal: false }),
    });

  const openModalOptions = ({
    mensaje,
    onAccept,
    onCancel = () => setState({ openModal: false }),
  }) =>
    setState({
      openModal: true,
      mensaje,
      onModalAccept: onAccept,
      onModalCancel: onCancel,
    });

  const gastosDetalles = useMemo(() => {
    const { gastos = [] } = state;
    return gastos.map((gasto) => ({
      ...gasto,
      feProceso: dateString(gasto.feProceso).format('DD/MM/YYYY HH:mm:ss'),
      estadosDocumento: (
        <ActualizarEstado
          gasto={gasto}
          codigoEmisor={userAttributes.codigoEmisor}
          tarifas={userAttributes.tarifas}
          consultarGastos={consultar}
          loading={setSpinnerModal}
          openModal={openModal}
        />
      ),
      btnDescarga: (
        <ButtonDescargaArchivos
          fileName={gasto.dsUrlXml}
          icon={<Icon icon='xls' height='35' width='35' />}
        />
      ),
    }));
  }, [state.gastos]);

  return (
    <Grid container xs={12}>
      <div className='form-row justify-content-center'>
        <Paper
          className='form-group col-lg'
          style={{ textAlign: 'center', margin: '15px', padding: '15px' }}
        >
          <Typography variant='labelEncabezado' style={{ marginBottom: '20px' }}>
            Subir y procesar masivamente
          </Typography>
          <RegistrarGasto
            emisor={userAttributes.codigoEmisor}
            consultarGastos={consultar}
            loading={setSpinnerModal}
            openModal={openModal}
            openModalOptions={openModalOptions}
          />
        </Paper>
        <Paper
          className='form-group col-lg'
          style={{ textAlign: 'center', margin: '15px', padding: '15px' }}
        >
          <Typography variant='labelEncabezado' style={{ marginBottom: '20px' }}>
            Consultar
          </Typography>
          <FiltroConsultas handleChange={handleChange} consultarGastos={consultar} state={state} />
        </Paper>
      </div>

      <Grid container xs={12} style={{ marginTop: '2%' }}>
        <Typography variant='labelEncabezado' style={{ marginBottom: '20px' }}>
          Resultados de busqueda
        </Typography>
        <Table
          orderBy={'feDocumento'}
          loading={state.loading}
          headers={[
            { label: 'Fecha documento', id: 'feProceso', disablePadding: true },
            { label: 'Nombre emisor', id: 'dsNombreEmisor', disablePadding: false },
            { label: 'Prefijo', id: 'dsPrefijo', disablePadding: true },
            { label: 'Folio', id: 'dsNumero', disablePadding: true },
            { label: 'CUFE', id: 'dsCufe', disablePadding: true },
            { label: 'Estado', id: 'estadosDocumento', disablePadding: false },
            { label: 'Descargar XML', id: 'btnDescarga', disablePadding: true },
          ]}
          keys={[
            'feProceso',
            'dsNombreEmisor',
            'dsPrefijo',
            'dsNumero',
            'dsCufe',
            'estadosDocumento',
            'btnDescarga',
          ]}
          detalles={gastosDetalles}
        />
      </Grid>
      <Modal
        open={state.openModal}
        content={<div>{state.mensaje}</div>}
        onAccept={state.onModalAccept}
        onCancel={state.onModalCancel}
      />
    </Grid>
  );
};

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gastos);
