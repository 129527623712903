import * as React from 'react';
import Modal from 'components/molecules/Modal';
import useModal from 'hooks/useModal';
import { consultarResolucionPuntoDeVentaPos } from 'network/Api/PuntoVentaPos';
import { now, getDateFromArray } from 'utils/DateUtils';
const { useCallback, useEffect } = React;

export default function ModalVencimientoResoluciones({ emisor, resolucion, tipo, prefijo }) {
  const [modalProps, openModal] = useModal();

  useEffect(() => {
    if (emisor) {
      validarResolucion();
    }
  }, [emisor, resolucion, tipo, prefijo]);

  const validarResolucion = useCallback(async () => {
    const { feVencimiento, dsResolucionDian, dsPrefijo } = await consultarResolucion();
    const proximoVencerse = now().add(30, 'days') >= getDateFromArray(feVencimiento);
    if (proximoVencerse) {
      openModal({
        content: `El prefijo ${dsPrefijo} de la resolución ${dsResolucionDian} está próximo a vencer. Valide las fechas a través de la opción "Configurar Resoluciones". Esta solicitud debe ser realizada por su contador directamente ante la DIAN. Además, debe tener en cuenta en qué consecutivo termina en PortalFactura para solicitar la nueva habilitación.`,
        title: 'Notificacion resolucion',
      });
    }
  }, []);

  const consultarResolucion = useCallback(async () => {
    if (tipo === 'POS') {
      const { data } = await consultarResolucionPuntoDeVentaPos();
      return data;
    }
  }, [tipo]);

  return <Modal {...modalProps} />;
}
