import { serviceRoutes, get } from './index';

export const consultarPuntosVentaPos = async () =>
  await get(serviceRoutes.consultarPuntosVentaPos, ``);

export const consultarPuntoDeVentaPos = async () =>
  await get(serviceRoutes.consultarPuntoDeVentaPos, ``);

export const consultarResolucionPuntoDeVentaPos = async () =>
  await get(serviceRoutes.consultarResolucionPuntoDeVentaPos, ``);
