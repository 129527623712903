import * as React from 'react';
import ButtonFile from 'components/molecules/ButtonFile';
import Modal from 'components/molecules/Modal';
import { subirExcelCufesGastos, consultarSaldoMasivoExcel } from 'network/Api/Gasto';
import useModal from 'hooks/useModal';
const { useCallback } = React;

function ButtonExcel({ emisor, datosHistoricosCufe }) {
  const [modalProps, openModal] = useModal();

  const onChangeFile = useCallback(
    async function (event) {
      let [file] = event.target.files;
      const validarSaldo = await consultarSaldoMasivoExcel({ emisor, file });
      const errores = validarFile(file, validarSaldo.data);
      if (!errores.length) {
        subirExcelCufesGastos({ emisor, file });
        openModal({
          content: 'Archivo en proceso. Puedes cerrar, y el sistema seguirá trabajando.',
        });
        [file] = '';
      } else {
        openModal({ content: errores.join(', ') });
      }
    },
    [emisor, datosHistoricosCufe],
  );

  const validarFile = useCallback(
    function (file, validarSaldo) {
      let errores = [];
      const { name } = file;
      if (!validarSaldo) {
        errores.push(
          'No cuenta con saldo suficiente para procesar los documentos. por favor recargar antes de continuar con la operación. ',
        );
      }
      if (
        datosHistoricosCufe &&
        datosHistoricosCufe.nmRegistrosProcesados < datosHistoricosCufe.nmCantidadRegistros
      ) {
        errores.push(
          'Actualmente hay un proceso en ejecución. Una vez finalice, podrá subir otro documento. ',
        );
      }
      if (!(name.endsWith('.xls') || name.endsWith('.xlsx'))) {
        errores.push('El archivo no es de tipo Excel. ');
      }
      return errores;
    },
    [datosHistoricosCufe],
  );

  return (
    <>
      <ButtonFile onChange={onChangeFile}>Subir y Procesar</ButtonFile>
      <Modal {...modalProps} />
    </>
  );
}

export default ButtonExcel;
